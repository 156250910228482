import createDataContext from "./createContext";
import { SpedizioniService } from "../api";
import { useUrlClient } from "../hooks";
import { sendNotification } from "../hooks/useNotification";
import { ErrorTitle } from "../components";

const MAGMOVIM_MD = "magmovim_md";
const DISPALY_TEXT = "display_text";
const CUSTOM_COLUMN = "custom_column";
const RICERCA_MAG_MOVIMENTI = "ricerca_mag_movimenti";
const COLONNE_MOVIMENTO = "colonne_movimento";
const MOVIMENTO_DATA = "movimento_data";


const INITIAL_STATE = {
  magMovim: [],
  displayText: "",
  customColumn: [],
  RicercaColumns: [],
  RicercaMovimenti: [],
  RicercaCount: [],
  MovimentoColumns : [],
  movimento : [],
  infoMovimento : "",
};

const posizioneMerceReducer = (state, action) => {
  switch (action.type) {
    case MAGMOVIM_MD: {
      return { ...state, magMovim: action.payload };
    }
    case DISPALY_TEXT: {
      return { ...state, displayText: action.payload };
    }
    case CUSTOM_COLUMN: {
      return { ...state, customColumn: action.payload };
    }
    case RICERCA_MAG_MOVIMENTI : {
      return { 
        ...state, 
        RicercaColumns: action.payload.columns,
        RicercaMovimenti: action.payload.movimenti,
        RicercaCount:action.payload.recordCount
      };
    }
    case COLONNE_MOVIMENTO: {
      return { ...state, MovimentoColumns: action.payload };
    }
    case MOVIMENTO_DATA: {
      return { ...state, movimento: action.payload.movimento, infoMovimento:action.payload.infoMovimento };
    }
    default: {
      return state;
    }
  }
};

const getBarcodeInfo = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/barcode/${code}?causale="ent"`
  );
  if (response.data) {
    navigate(`/posizione-merce-mag-entrata/${code}`);
    dispatch({ type: MAGMOVIM_MD, payload: response.data.magmerce });
    dispatch({ type: DISPALY_TEXT, payload: response.data.displaytext });
    dispatch({ type: CUSTOM_COLUMN, payload: response.data.columns });
  }
};

const getBarcodeInfoUscita = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/barcode/${code}?causale="usc"`
  );
  if (response.data) {
    navigate(`/posizione-merce-mag-uscita/${code}`);
    dispatch({ type: MAGMOVIM_MD, payload: response.data.magmerce });
    dispatch({ type: DISPALY_TEXT, payload: response.data.displaytext });
    dispatch({ type: CUSTOM_COLUMN, payload: response.data.columns });
  }
};

const postMagMovim = (dispatch) => async (payload, code, magMovim) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/magmovim/ubicazione`,
    {
      PrgMovimento: magMovim[0].PrgMovimento,
      RiferimPaletta: payload.RiferimPaletta,
      Ubicazione: payload.Ubicazione,
    }
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "Elemento inserito correttamente",
      "success",
      5
    );
    getRefreshInfoBarcode(dispatch)(code);
  }
};

const postUscitaMagMovim =
  (dispatch) => async (code, prgmovimento, riferimpaletta) => {
    const response = await SpedizioniService.post(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/registra-uscita-riferimpaletta/${prgmovimento}/${riferimpaletta} `
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"Success!"} sound={"success"} />,
        "Ok Elemento Caricato Correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getRefreshInfoBarcode(dispatch)(code);
  };

const getRefreshInfoBarcode = (dispatch) => async (code) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/barcode/${code}`
  );
  if (response.data) {
    dispatch({ type: MAGMOVIM_MD, payload: response.data.magmerce });
    dispatch({ type: DISPALY_TEXT, payload: response.data.displaytext });
    dispatch({ type: CUSTOM_COLUMN, payload: response.data.magmerce_columns });
  }
};

const postAssegnazioneSSCC = (dispatch) => async (payload) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/magmovim/riferimpaletta-riferimsscc`,
    {
      RiferimPaletta: payload.RiferimPaletta,
      RiferimSSCC: payload.RiferimSSCC,
    }
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "Elemento associato correttamente",
      "success",
      5
    );
  }
};

const getRicercaMagMovimenti = (dispatch) => async (causale) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/movimenti/search?causale=${causale}`
  );
  if (response.data) {
    dispatch({
      type: RICERCA_MAG_MOVIMENTI,
      payload: { 
        movimenti: response.data.data,
        columns: response.data.columns,
        recordCount: response.data.RecordCount
       },
    });      
  }
};


const getMagMovimDataEntrata =
  (dispatch) => async (PrgMovimento,  navigate,detailPath) => {
    const response = await SpedizioniService.get(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/movimenti/entrata/${PrgMovimento}`
    );
    if (response.data) {
      dispatch({ type: COLONNE_MOVIMENTO, payload: response.data.columns });
      dispatch({
        type: MOVIMENTO_DATA,
        payload: { movimento: response.data.data, infoMovimento: response.data.infoText },
      });
    }

    if(navigate){
        navigate(`/merce-mag-log-entrata-dettaglio/${PrgMovimento}`);
    }
  };

  
  const getMagMovimDataUscita =
  (dispatch) => async (PrgMovimento, navigate) => {
    const response = await SpedizioniService.get(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/movimenti/uscita/${PrgMovimento}`
    );
    if (response.data) {
      dispatch({ type: COLONNE_MOVIMENTO, payload: response.data.columns });
      dispatch({
        type: MOVIMENTO_DATA,
        payload: { movimento: response.data.data, infoMovimento: response.data.infoText },
      });
    }

    if(navigate){
        navigate(`/merce-mag-log-uscita-dettaglio/${PrgMovimento}`);
    }
  };


  const postRegistraMovimentoEntrata =
  (dispatch) => async (prgMovimento, articolo, navigate) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/magmovim/registra-entrata/${parseInt(prgMovimento)}/${articolo}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getMagMovimDataEntrata(dispatch)(prgMovimento,navigate,'');
  };


  const postRegistraMovimentoUscita =
  (dispatch) => async (prgMovimento, articolo) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/magmovim/registra-uscita/${parseInt(prgMovimento)}/${articolo}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getMagMovimDataUscita(dispatch)(prgMovimento,undefined,'');
  };


  const getMagUBIMovimDataEntrata = (dispatch) => async (PrgMovimento,  navigate,detailPath) => {
    const response = await SpedizioniService.get(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/movimenti/entrata/${PrgMovimento}`
    );
    if (response.data) {
      dispatch({ type: COLONNE_MOVIMENTO, payload: response.data.columns });
      dispatch({
        type: MOVIMENTO_DATA,
        payload: { movimento: response.data.data, infoMovimento: response.data.infoText },
      });
    }

    if(navigate){
        navigate(`/merce-mag-log-entrata-ubi-dettaglio/${PrgMovimento}`);
    }
  };

  const getMagUBIMovimDataUscita =
  (dispatch) => async (PrgMovimento, navigate) => {
    const response = await SpedizioniService.get(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/movimenti/uscita/${PrgMovimento}`
    );
    if (response.data) {
      dispatch({ type: COLONNE_MOVIMENTO, payload: response.data.columns });
      dispatch({
        type: MOVIMENTO_DATA,
        payload: { movimento: response.data.data, infoMovimento: response.data.infoText },
      });
    }

    if(navigate){
        navigate(`/merce-mag-log-uscita-ubi-dettaglio/${PrgMovimento}`);
    }
  };


  //TODO: il form potrei inserirlo come object nel contesto corrente
  const getDeterminaBarcode =
  (dispatch) => async (barcodeForm, setDialogUbicazioniOpen, setValues, prgMovimento) => {

    
    const response = await SpedizioniService.get(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/determina/${barcodeForm.Barcode}?PrgMovimento=${prgMovimento}`

    );
    if (response.data) {
      if (response.data.entita === 'Ubicazione'){
        setValues({
          ...barcodeForm,
          //con ubicazione non voglio salvare l'entità
          //Entita : response.data.entita,
          Ubicazione: response.data.barcode,
          CommCodice: response.data.CommCodice, 
          GruppoGestioneMag:response.data.GruppoGestioneMag,
          //svuoto anche barcode, così non me lo porto dietro inutilmente
          Barcode:''
        });
      }
      else if (response.data.entita === 'Articolo'){
        setValues({
          ...barcodeForm,
          Entita : response.data.entita,
          Articolo: response.data.barcode,
          CommCodice: response.data.CommCodice, 
          GruppoGestioneMag:response.data.GruppoGestioneMag,          
          //svuoto anche barcode, così non me lo porto dietro inutilmente
          Barcode:''
        });
      }
      else if (response.data.entita === 'GS1'){
        setValues({
          ...barcodeForm,
          Entita : response.data.entita,
          Articolo: response.data.codicearticolo,
          CommCodice: response.data.CommCodice, 
          GruppoGestioneMag:response.data.GruppoGestioneMag ,          
          //svuoto anche barcode, così non me lo porto dietro inutilmente
          Barcode:''       
        });
      }
      else if (response.data.entita === 'RiferimPaletta'){

        setValues({
          ...barcodeForm,
          Entita : response.data.entita, 
          Articolo: barcodeForm.Barcode,
          CommCodice: response.data.CommCodice, 
          GruppoGestioneMag:response.data.GruppoGestioneMag,
          //svuoto anche barcode, così non me lo porto dietro inutilmente
          Barcode:''
        });
      }

      setDialogUbicazioniOpen(true)
    }
  };

  const putRegistraRigaMerceEntrata =
  (dispatch) => async (barcodeForm, dialogClose, setValues, prgMovimento) => {

    //se letto prima ubicazione e poi barcode non ho in mano il tipo di entità.
    //valutare se effettuare questo calcolo lato backend per migliorare leggermente prestazioni.
    //Riscontrato in CSI
    if (barcodeForm?.Entita === '' && barcodeForm?.Articolo !== ''){
      const BarCodeResponse = await SpedizioniService.get(
        `${
          (
            await useUrlClient()
          ).baseUrl
        }/magmovim/determina/${barcodeForm.Articolo}?PrgMovimento=${prgMovimento}`  
      );

      if(BarCodeResponse?.data?.entita){
        barcodeForm.Entita = BarCodeResponse.data.entita;
      }
    }

    const response = await SpedizioniService.put(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/merce-entrata`,
      {
        codicearticolo : barcodeForm.Articolo,
        commcodice : barcodeForm.CommCodice,
        ubicazione: barcodeForm.Ubicazione,
        entita : barcodeForm.Entita,
        gruppogestionemag : barcodeForm.GruppoGestioneMag,
        colli : barcodeForm.Quantita,
        prgmovimento : prgMovimento
      }

    );
    if (response.data) {

      if (response.data.Success){
        dialogClose()
        sendNotification(
          <ErrorTitle service={"OK!"} sound={"success"} />,
          response.data.Message,
          "success",
          5
        );
      }
      else {
        sendNotification(
          <ErrorTitle service={"Errore!"} sound={"warning"} />,
          response.data.Message,
          "error",
          5
        );
      }
    }


  };

  const putRegistraRigaMerceUscita =
  (dispatch) => async (barcodeForm, dialogClose, setValues, prgMovimento) => {
    //se letta prima ubicazione e poi barcode non ho in mano il tipo di entità.
    //valutare se effettuare questo calcolo lato backend per migliorare leggermente prestazioni.
    //Riscontrato in CSI
    if (barcodeForm?.Entita === '' && barcodeForm?.Articolo !== ''){
      const BarCodeResponse = await SpedizioniService.get(
        `${
          (
            await useUrlClient()
          ).baseUrl
        }/magmovim/determina/${barcodeForm.Articolo}?PrgMovimento=${prgMovimento}`  
      );

      if(BarCodeResponse?.data?.entita){
        barcodeForm.Entita = BarCodeResponse.data.entita;
      }
    }

    const response = await SpedizioniService.put(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/merce-uscita`,
      {
        codicearticolo : barcodeForm.Articolo,
        commcodice : barcodeForm.CommCodice,
        ubicazione: barcodeForm.Ubicazione,
        entita : barcodeForm.Entita,
        gruppogestionemag : barcodeForm.GruppoGestioneMag,
        colli : barcodeForm.Quantita,
        prgmovimento : prgMovimento
      }
    );
    if (response.data) {

      if (response.data.Success){

        dialogClose()
        sendNotification(
          <ErrorTitle service={"OK!"} sound={"success"} />,
          response.data.Message,
          "success",
          5
        );

        
      }
      else {
        sendNotification(
          <ErrorTitle service={"Errore!"} sound={"warning"} />,
          response.data.Message,
          "error",
          5
        );
      }
    }
  };



export const { Provider, Context } = createDataContext(
  posizioneMerceReducer,
  {
    getBarcodeInfo,
    getRefreshInfoBarcode,
    postMagMovim,
    postUscitaMagMovim,
    getBarcodeInfoUscita,
    postAssegnazioneSSCC,
    getRicercaMagMovimenti,
    getMagMovimDataEntrata,
    getMagMovimDataUscita,
    postRegistraMovimentoEntrata,
    postRegistraMovimentoUscita,
    getMagUBIMovimDataEntrata,
    getMagUBIMovimDataUscita,
    getDeterminaBarcode,
    putRegistraRigaMerceUscita,
    putRegistraRigaMerceEntrata

  }, // actions
  INITIAL_STATE // initial state
);
