import createDataContext from "./createContext";
import { SpedizioniService } from "../api";
import { useUrlClient } from "../hooks";
import { sendNotification } from "../hooks/useNotification";
import { ErrorTitle } from "../components";
import { type } from "@testing-library/user-event/dist/type";
import { responsiveFontSizes } from "@mui/material";

const CARICO_MERCE = "carico_merce";
const VIAGGIO = "viaggio";
const INFO_VIAGGIO = "info_viaggio";
const INFO_COLLI = "info_colli";
const COLONNE = "colonne";
const VIAGGIO_BCODE = "viaggio_bcode";
const MESSAGE_DIALOG = "message_dialog";
const COLLI_QUALITA = "colli_qualita";
const PARTENZA_TRAZIONE_RICERCA = "partenza_trazione_ricerca";
const ARRIVO_TRAZIONE_RICERCA = "arrivo_trazione_ricerca";
const PARTENZA_DISTRIBUZIONE_RICERCA = "arrivo_trazione_ricerca";
const RESET_RICERCA_DATA = "reset_ricerca_data";
const SEGNACOLLO_APPARTIENE =  "segnacollo_appartiene";
const SEGNACOLLO_ELIMINAZIONE = "segnacollo_eliminazione";
const ERROR_MESSAGE = "error_message";
const SET_TERMINAL = "set_terminal"
const ARRIVO_DISTINTA_RICERCA = "arrivo_distinta_ricerca";
const DISTINTA_BCODE = "viaggio_bcode";
const COLONNE_DISTINTA = "colonne_distinta";




const INITIAL_STATE = {
  caricoMerce: [],
  viaggio: [],
  Distinta:[],
  infoViaggio: [],
  infoColli: [],
  columns: [],
  distintaData:[],
  messageDialog: "",
  listaColliQ:[],
  RicercaColumns:[],
  RicercaViaggi:[],
  RicercaDistinte:[],
  RicercaCount:0,
  SegnacolloAppartiene:{},
  SegnacolloEliminazione:{},
  ErrorMessage: "",
  terminal : null,
};

const caricoMerceUscitaReducer = (state, action) => {
  switch (action.type) {
    case CARICO_MERCE: {
      return { ...state, caricoMerce: action.payload };
    }
    case PARTENZA_TRAZIONE_RICERCA: {
      return { ...state, 
        RicercaColumns: action.payload.columns,
        RicercaViaggi: action.payload.viaggi,
        partTrazCount:action.payload.recordCount
      };
    }
    case RESET_RICERCA_DATA: {
      return { ...state, 
        RicercaColumns: action.payload.columns,
        RicercaViaggi: action.payload.viaggi,
        partTrazCount:action.payload.recordCount
      };
    }
    case ARRIVO_TRAZIONE_RICERCA: {
      return { ...state, 
        RicercaColumns: action.payload.columns,
        RicercaViaggi: action.payload.viaggi,
        RicercaCount:action.payload.recordCount
      };
    }
    case PARTENZA_DISTRIBUZIONE_RICERCA: {
      return { ...state, 
        RicercaColumns: action.payload.columns,
        RicercaViaggi: action.payload.viaggi,
        RicercaCount:action.payload.recordCount
      };
    }
    case VIAGGIO: {
      return {
        ...state,
        viaggio: action.payload.spedizioni,
        infoViaggio: action.payload,
      };
    }
    case INFO_VIAGGIO: {
      return { ...state, infoViaggio: action.payload };
    }
    case INFO_COLLI: {
      return { ...state, infoColli: action.payload };
    }
    case COLONNE: {
      return { ...state, columns: action.payload };
    }
    case VIAGGIO_BCODE: {
      return {
        ...state,
        viaggio: action.payload.barcode,
        infoViaggio: action.payload.infoText,
      };
    }
    case MESSAGE_DIALOG: {
      return { ...state, messageDialog: action.payload };
    }
    case COLLI_QUALITA: {
      const { listaColliQ } = action.payload
      return {
        ...state,
        listaColliQ
      };
    }
    case SEGNACOLLO_APPARTIENE:{
      const {SegnacolloAppartiene} = action.payload;
      return{
        ...state, SegnacolloAppartiene
      };
    }
    case SEGNACOLLO_ELIMINAZIONE:{
      const {SegnacolloEliminazione} = action.payload;
      return{
        ...state, SegnacolloEliminazione
      };
    }
    case ERROR_MESSAGE: {
      return { ...state, ErrorMessage: action.payload };
    }
    case SET_TERMINAL: {
      const {terminal} = action.payload;
      return { ...state, terminal };
    }
    case ARRIVO_DISTINTA_RICERCA: {
      return { ...state, 
        RicercaColumns: action.payload.columns,
        RicercaDistinte: action.payload.distinte,
        partTrazCount:action.payload.recordCount
      };
    }
    case DISTINTA_BCODE: {
      return {
        ...state,
        Distinta: action.payload.barcode,
        infoViaggio: action.payload.infoText,
      };
    }
    case COLONNE_DISTINTA: {
      return { 
        ...state,
        columns: action.payload.colonne,
        Distinta: action.payload.rows,
        infoText: action.payload.infoText 
      };
    }
    default: {
      return state;
    }
  }
};

const getCaricoMerce = (dispatch) => async (date) => {
  dispatch({ type: CARICO_MERCE, payload: [] });
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/viaggi-del-giorno/${date}`
  );
  if (response.data) {
    dispatch({ type: CARICO_MERCE, payload: response.data.viaggio });
    dispatch({ type: COLONNE, payload: response.data.columns });
  }
};

const updateSpedizione =
  (dispatch) => async (prgviaggio, prgspedizione, body, setOpen) => {
    const response = await SpedizioniService.put(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/viaggi/azione/carico/${prgviaggio}/${prgspedizione}`,
      {
        codiceevento: body.codiceevento,
        note: body.note,
        NotaViaggio_Autista: body.NotaViaggio_Autista,
      }
    );
    if (response.data) {
      getViaggio(dispatch)(prgviaggio);
      setOpen(false);
    }
  };

const getViaggio = (dispatch) => async (prgViaggio) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/viaggio-per-carico/${prgViaggio}`
  );
  if (response.data) {
    dispatch({ type: VIAGGIO, payload: response.data });
  }
};

const getInfoColli = (dispatch) => async (prgspedizione) => {
  dispatch({ type: INFO_COLLI, payload: [] });
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/spedizioni/spedmerce/${prgspedizione}`
  );
  if (response.data) {
    dispatch({ type: INFO_COLLI, payload: response.data.spedmerce });
  }
};

// Partenza per distribuzione.
const getPartenzaDistribuzione =
  (dispatch) => async (prgViaggioBarcode, navigate) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi?barcode=${prgViaggioBarcode}`
    );
    if (response.data) {
      navigate(`/partenza-distribuzione/${response.data.PrgViaggio}`);
    }
  };

const getPartenzaDistribuzioneData =
  (dispatch) => async (PrgViaggio, navigate) => {
    const response = await SpedizioniService.get(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/viaggi/partenza-distribuzione/${PrgViaggio}`
    );
    if (response.data) {
      dispatch({ type: COLONNE, payload: response.data.columns });
      dispatch({
        type: VIAGGIO_BCODE,
        payload: { barcode: response.data.data, infoText: response.data },
      });
    }
  };

const postPartenzaDistribuzione =
  (dispatch) => async (code, prgmovimento, riferimpaletta) => {
    const response = await SpedizioniService.post(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/viaggi/partenza-distribuzione/${parseInt(code)}/${riferimpaletta}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getPartenzaDistribuzioneData(dispatch)(code);
  };

// Partenza per trazione.
const getPartenzaTrazione =
  (dispatch) => async (prgViaggioBarcode, navigate) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi?barcode=${prgViaggioBarcode}`
    );
    if (response.data) {
      navigate(`/partenza-per-trazione/${response.data.PrgViaggio}`);
    }
  };

const getPartenzaPerTrazioneData =
  (dispatch) => async (PrgViaggio, navigate) => {
    try{
      const response = await SpedizioniService.get(
        `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/${PrgViaggio}`
      );
      if (response.data) {
        dispatch({ type: COLONNE, payload: response.data.columns });
        dispatch({
          type: VIAGGIO_BCODE,
          payload: { barcode: response.data.data, infoText: response.data },
        });
      }
    }
    catch{
      navigate("/partenza-per-trazione")
    }  
    
  };


const postPartenzaPerTrazione =
  (dispatch) => async (code, prgmovimento, riferimpaletta,confermainserimento, setDialogEliminazione, setDialogNonPrevisto, resetRiferimPaletta) => {

    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/${parseInt(code)}/${riferimpaletta}?confermainserimento=${confermainserimento}`
    );
    if (response.data) {
      if (response.data.ControllaSeBarCodeSpedGiaLetto !== 0) {
        sendNotification(
        <ErrorTitle service={'OK!'} sound={"error"} />,
        response.data.Message,
        "warning",
        2
        ); 
        dispatch({ type: MESSAGE_DIALOG, payload: response.data });
        setDialogEliminazione(true);
      } else {
        //if (response.data.inserimento)
        if (response.data.Previsto === false){
          if (response.data.Inserimento === true){
            console.log("MOSTRARE DIALOGO")
            sendNotification(
              <ErrorTitle service={'OK!'} sound={"error"} />,
                response.data.Message,
                "warning",
                2
              ); 
              dispatch({ type: MESSAGE_DIALOG, payload: response.data });
              setDialogNonPrevisto(true);
          }
          else {
            sendNotification(
              <ErrorTitle service={"OK!"} sound={"success"} />,
              response.data.Message,
              "success",
              5
            );

            resetRiferimPaletta();
          }
        }
        else if(response.data.Success == true){
        sendNotification(
          <ErrorTitle service={"OK!"} sound={"success"} />,
            response.data.Message,
            "success",
            5
          );
        }
        else {
          sendNotification(
            <ErrorTitle service={"Attenzione!"} sound={"error"}/>,
            "Elemento non trovato",
            "warning",
            5
          );
        }
      }
    }
    getPartenzaPerTrazioneData(dispatch)(code);
  };


// Arrivo per trazione.
const getArrivoTrazione = (dispatch) => async (prgViaggioBarcode, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi?barcode=${prgViaggioBarcode}`
  );
  if (response.data) {
    navigate(`/arrivo-da-trazione/${response.data.PrgViaggio}`);
  }
};

const getArrivoPerTrazioneData = (dispatch) => async (PrgViaggio, navigate) => {
  try {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi/arrivo-trazione/${PrgViaggio}`
    );
    if (response.data) {
      dispatch({ type: COLONNE, payload: response.data.columns });
      dispatch({
        type: VIAGGIO_BCODE,
        payload: { barcode: response.data.data, infoText: response.data },
      });
    }
  } catch (error) {
    navigate("/arrivo-da-trazione");
  }
};

const postArrivoPerTrazione =
  (dispatch) => async (code, prgmovimento, riferimpaletta) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/arrivo-trazione/${parseInt(
        code
      )}/${riferimpaletta}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getArrivoPerTrazioneData(dispatch)(code);
  };

const getColliQualita = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/controllo/barcode/${code}`
  );
  if (response.data) {
    navigate(
      `/cerca-colli-controllo-qualita/${response.data.campichiave.PrgControllo}`
    );
  }
};

const getRicercaColliQualita = (dispatch) => async () => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/controllo/ricerca`
    );
    if(response.data){
      dispatch({ type: COLONNE, payload: response.data.columns });
      dispatch({
        type: COLLI_QUALITA,
        payload: { listaColliQ: response.data.data },
      });
    }
};

const getControlloColloData = (dispatch) => async (PrgControllo, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/controllo/${PrgControllo}`
  );
  if (response.data) {
    dispatch({ type: COLONNE, payload: response.data.columns });
    dispatch({
      type: VIAGGIO_BCODE,
      payload: { barcode: response.data.data, infoText: response.data },
    });
  }
};

const postControlloColloData =
  (dispatch) => async (code, prgControllo, barcode) => {
    const response = await SpedizioniService.put(
      `${(await useUrlClient()).baseUrl}/magmovim/controllo/record`,
      {
        PrgControllo: prgControllo,
        Barcode: barcode,
      }
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getControlloColloData(dispatch)(code);
  };



  const getRicercaPartenzaTrazione = (dispatch) => async (navigate) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/ricerca`
    );
    if (response.data) {

      dispatch({
        type: PARTENZA_TRAZIONE_RICERCA,
        payload: { 
          viaggi: response.data.viaggi,
          columns: response.data.columns,
          recordCount: response.data.RecordCount
         },
      });    

    }
  };

  
const getPartenzaPerTrazioneViaggio = 
(dispatch) => async(PrgViaggio, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/${PrgViaggio}`
  );
  if (response.data) {
    dispatch({ type: COLONNE, payload: response.data.columns });
    dispatch({
      type: VIAGGIO_BCODE,
      payload: { barcode: response.data.data, infoText: response.data },
    });
    navigate(`/partenza-per-trazione/${PrgViaggio}`);
  };
};

const getRicercaArrivoTrazione = (dispatch) => async (navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/arrivo-trazione/ricerca`
  );
  if (response.data) {
    dispatch({
      type: ARRIVO_TRAZIONE_RICERCA,
      payload: { 
        viaggi: response.data.viaggi,
        columns: response.data.columns,
        recordCount: response.data.RecordCount
       },
    });      
  }
};

const getArrivoDaTrazioneViaggio = 
(dispatch) => async(PrgViaggio, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/arrivo-trazione/${PrgViaggio}`
  );
  if (response.data) {
    dispatch({ type: COLONNE, payload: response.data.columns });
    dispatch({
      type: VIAGGIO_BCODE,
      payload: { barcode: response.data.data, infoText: response.data },
    });
    navigate(`/arrivo-da-trazione/${PrgViaggio}`);
  };
};

const getRicercaPartenzaDistribuzione = (dispatch) => async (navigate) => {

  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/partenza-distribuzione/ricerca`
  );
  if (response.data) {
    
    dispatch({
      type: PARTENZA_DISTRIBUZIONE_RICERCA,
      payload: { 
        viaggi: response.data.viaggi,
        columns: response.data.columns,
        recordCount: response.data.RecordCount
      },
    });      
  }
};

const getPartenzaPerDistribuzioneViaggio = 
(dispatch) => async(PrgViaggio, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/partenza-distribuzione/${PrgViaggio}`
  );
  if (response.data) {
    dispatch({ type: COLONNE, payload: response.data.columns });
    dispatch({
      type: VIAGGIO_BCODE,
      payload: { barcode: response.data.data, infoText: response.data },
    });
    navigate(`/partenza-distribuzione/${PrgViaggio}`);
  };
  
};

const postRientroDistribuzione =
  (dispatch) => async (code, /*prgmovimento, riferimpaletta*/) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/rientro-distribuzione/${parseInt(
        code
      )}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getArrivoPerTrazioneData(dispatch)(code);
  };

const postChiusuraPartenzaDistribuzione =
  (dispatch) => async (prgviaggio, navigate) => {

    //let response = null;
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-distribuzione/completato/${parseInt(
        prgviaggio
      )}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Viaggio chiuso con successo.",
        "success",
        5
      );

    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    //getArrivoPerTrazioneData(dispatch)(code);
  };

  const postChiusuraPartenzaTrazione =
  (dispatch) => async (prgviaggio, navigate) => {

    //let response = null;
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/completato/${parseInt(
        prgviaggio
      )}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Viaggio chiuso con successo.",
        "success",
        5
      );
      

      if (navigate){
        navigate(-1)
      } 
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    //getArrivoPerTrazioneData(dispatch)(code);
  };

  const resetRicercaData = (dispatch) => async (navigate) => {
    dispatch({
      
        type: RESET_RICERCA_DATA,
        payload: { 
          viaggi: [],
          columns:[],
          recordCount: 0
        },
      });      
  };

  const getRicercaRientroDistribuzione = (dispatch) => async (navigate) => {

    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi/rientro-distribuzione/ricerca`
    );
    if (response.data) {
      
      dispatch({
        type: PARTENZA_DISTRIBUZIONE_RICERCA,
        payload: { 
          viaggi: response.data.viaggi,
          columns: response.data.columns,
          recordCount: response.data.RecordCount
        },
      });      
    }
  };

  const getRientroDistribuzione = (dispatch) => async (prgViaggioBarcode, navigate) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi?barcode=${prgViaggioBarcode}`
    );
    if (response.data) {
      navigate(`/rientro-da-distribuzione/${response.data.PrgViaggio}`);
    }
  };

  const getRientroDistribuzioneViaggio = (dispatch) => async (PrgViaggio, navigate) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi/rientro-distribuzione/${PrgViaggio}`
    );
    if (response.data) {
      dispatch({ type: COLONNE, payload: response.data.columns });
      dispatch({
        type: VIAGGIO_BCODE,
        payload: { barcode: response.data.data, infoText: response.data },
      });
      navigate(`/rientro-da-distribuzione/${PrgViaggio}`);
    };
  };

const postRientroDistribuzioneViaggioSegnacollo =
  (dispatch) => async (code, riferimpaletta) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/rientro-distribuzione/${parseInt(
        code
      )}/${riferimpaletta}`
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getArrivoPerTrazioneData(dispatch)(code);
  };



  const controlloAppartenenzaSegnacollo = (dispatch) => async(PrgViaggio, Segnacollo) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-distribuzione/controllo/${PrgViaggio}/${Segnacollo}`
    );

    if (response){
      dispatch({
        type: SEGNACOLLO_APPARTIENE,
        payload:{SegnacolloAppartiene:response.data}
    });
    }

  };



  const clearAppartenenzaSegnacollo = (dispatch) => async(PrgViaggio, Segnacollo) => {
    dispatch({
        type: SEGNACOLLO_APPARTIENE,
        payload:{SegnacolloAppartiene:{}}
    });
  };
  

  const controlloEliminazioneSegnacollo = (dispatch) => async(PrgViaggio, Segnacollo) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/controllo/eliminazione/${PrgViaggio}/${Segnacollo}`
    );
    
    if (response){
      dispatch({
        type: SEGNACOLLO_ELIMINAZIONE,
        payload:{SegnacolloEliminazione:response.data}
    });
    }

  };


  const clearEliminazioneSegnacollo = (dispatch) => async() => {
    dispatch({
        type: SEGNACOLLO_ELIMINAZIONE,
        payload:{SegnacolloEliminazione:{modalview:false}}
    });
  };

  const effettuaEliminazioneSegnacollo = (dispatch) => async(PrgViaggio, Segnacollo) => {
    const response = await SpedizioniService.delete(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/${PrgViaggio}/${Segnacollo}`
    );
    
    if (response){

      if (response.data.esito === 0){
        sendNotification(
          <ErrorTitle service={"OK!"} sound={"success"} />,
          "Ok elemento eliminato correttamente",
          "success",
          5
        );
      }
    }
    clearEliminazioneSegnacollo()
  };

  const postCambioViaggio =
  (dispatch) => async (prgNuovoViaggio, prgSpedizione, prgViaggio, navigate) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/cambio-viaggio-spedizione/${
        prgNuovoViaggio
      }/${
        parseInt(prgSpedizione)
      }/${
        parseInt(prgViaggio)}`
    );
    if (response.data) {
      if (response.data.Success){
        sendNotification(
          <ErrorTitle service={"OK!"} />,
          response.data.Message,
          "success",
          5
        );
        navigate(`/carico-merce-in-uscita/${prgViaggio}`)
      } else {
        sendNotification(
          <ErrorTitle service={"Attenzione!"} />,
          "Spostamento non effettuato",
          "warning",
          5
        );
      }
    }
  };

  const postCreaViaggioPartenzaTrazione =
  (dispatch) => async (formCreaViaggio, closeDialog) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione`,
      formCreaViaggio
    );
    if (response.data) {
      if (response.data.Success){
        sendNotification(
          <ErrorTitle service={"OK!"} />,
          response.data.Err_Message,
          "success",
          5
        );
        closeDialog();
        //navigate(`/carico-merce-in-uscita/${prgViaggio}`)
      } else {
        sendNotification(
          <ErrorTitle service={"Attenzione!"} />,
          "Viaggio non creato",
          "warning",
          5
        );
      }

      getRicercaPartenzaTrazione(dispatch)();
    }
  };

  const postCreaViaggioPartenzaDistribuzione =
  (dispatch) => async (formCreaViaggio, closeDialog) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-distribuzione`,
      formCreaViaggio
    );
    if (response.data) {
      if (response.data.Success){
        sendNotification(
          <ErrorTitle service={"OK!"} />,
          response.data.Err_Message,
          "success",
          5
        );
        closeDialog();
        //navigate(`/carico-merce-in-uscita/${prgViaggio}`)
      } else {
        sendNotification(
          <ErrorTitle service={"Attenzione!"} />,
          response.data.Err_Message,
          "warning",
          5
        );
      }

      getRicercaPartenzaDistribuzione(dispatch)();
    }
  };

  const getTerminal = (dispatch) => async (navigate) => {
    const response = await SpedizioniService.get(
        `${(await useUrlClient()).baseUrl}/terminal`
    );
    if(response.data){
        dispatch({ type: SET_TERMINAL, payload: response.data });
    }
    else{
        console.log("nada");
    }
};


const getRicercaArrivoDistinta = 
(dispatch) => async( ) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/arrivo-distinta/ricerca`
  );
  if (response.data.Success) {
    dispatch({
      type: ARRIVO_DISTINTA_RICERCA,
      payload: { 
        distinte: response.data.data,
        columns: response.data.columns,
        recordCount: response.data.RecordCount
      },
    });      
  }
  else {
    sendNotification(
      <ErrorTitle service={"Attenzione!"} />,
      response.data.Err_Message,
      "error",
      5
    );
  }
};


const getArrivoDistintaData = 
(dispatch) => async(PrgDistinta, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/arrivo-distinta/${PrgDistinta}`
  );
  if (response.data) {
    dispatch({ type: COLONNE_DISTINTA, payload: {colonne:response.data.columns,rows: response.data.data,infoText: response.data } });
    if(navigate !== undefined){
      navigate(`/arrivo-distinta/${PrgDistinta}`);
    };
  };
};



const postArrivoDistinta =
  (dispatch) => async (code, prgDistinta, barcodeLetto) => {
    const response = await SpedizioniService.post(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/viaggi/arrivo-distinta/${parseInt(prgDistinta)}/${barcodeLetto}`
    );

    if (response.data) {
      
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok elemento caricato correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getArrivoDistintaData(dispatch)(prgDistinta);
  };



export const { Provider, Context } = createDataContext(
  caricoMerceUscitaReducer,
  {
    getCaricoMerce,
    getViaggio,
    updateSpedizione,
    getInfoColli,
    getPartenzaTrazione,
    getPartenzaPerTrazioneData,
    postPartenzaPerTrazione,
    getArrivoTrazione,
    getArrivoPerTrazioneData,
    postArrivoPerTrazione,
    getPartenzaDistribuzione,
    getPartenzaDistribuzioneData,
    postPartenzaDistribuzione,
    getColliQualita,
    getControlloColloData,
    postControlloColloData,
    getRicercaColliQualita,
    getRicercaPartenzaTrazione,
    getPartenzaPerTrazioneViaggio,
    getRicercaArrivoTrazione,
    getArrivoDaTrazioneViaggio,
    getRicercaPartenzaDistribuzione,
    getPartenzaPerDistribuzioneViaggio,
    postRientroDistribuzione,
    getRientroDistribuzione,
    postChiusuraPartenzaDistribuzione,
    resetRicercaData,
    getRicercaRientroDistribuzione,
    getRientroDistribuzioneViaggio,
    postRientroDistribuzioneViaggioSegnacollo,
    controlloAppartenenzaSegnacollo,
    clearAppartenenzaSegnacollo,
    controlloEliminazioneSegnacollo,
    clearEliminazioneSegnacollo,
    effettuaEliminazioneSegnacollo,
    postCambioViaggio,
    postCreaViaggioPartenzaTrazione,
    postCreaViaggioPartenzaDistribuzione,
    postChiusuraPartenzaTrazione,
    getTerminal,
    getRicercaArrivoDistinta,
    getArrivoDistintaData,
    postArrivoDistinta
    
  }, // actions
  INITIAL_STATE // initial state
);
