import {  
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Slide
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "../hooks";
import {
  React,
  useRef,
  useEffect,
  forwardRef
} from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
  
const DialogUbicazioni = ({isOpen, setIsOpen, barcodeForm, setValues, changeHandler, dialogClose, registraRigaMerce, prgMovimento}) => {
  useEffect(() => {

    const focusInput = () => {
      if (isOpen){
        if (barcodeForm.Ubicazione === ""  ){
          //ubicazioneRef.current.focus();
          document.getElementById( "ubicazioneField" ).focus()           
        }
        else if (barcodeForm.Ubicazione !== "" && barcodeForm.Articolo === "" ){
          //articoloRef.current.focus();
          document.getElementById( "articoloField" ).focus()
        }
        else if (barcodeForm.Ubicazione !== "" && barcodeForm.Articolo !== "" && barcodeForm.Quantita === 0 ){
          // quantitaRef.current.focus();
          document.getElementById( "quantitaField" ).focus()
        }
        else{
          //ubicazioneRef.current.focus();
          document.getElementById( "ubicazioneField" ).focus()
        }
      }
    }


    // Ensuring the component is fully rendered before trying to focus
    const timeoutId = setTimeout(focusInput, 50);

    // Cleanup timeout if the component unmounts before the timeout executes
    return () => clearTimeout(timeoutId);

  }, [isOpen]);


  const OnRegistraClick = () => {
    registraRigaMerce(barcodeForm, dialogClose, setValues, prgMovimento);
    
  }

  return (
      <div>
        <Dialog  
          fullScreen
          open={isOpen}
          onClose={() => dialogClose()}
          TransitionComponent={Transition}  
        >


        {/* <DialogTitle>Creazione viaggio</DialogTitle> */}
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={dialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Registrazione merce
            </Typography>
            <Button variant="contained" autoFocus color="warning" onClick={dialogClose} sx={{mr:2}}>
            Annulla
            </Button>
            <Button variant="contained" autoFocus color="success" onClick={() => registraRigaMerce(barcodeForm, dialogClose, setValues, prgMovimento)}>
              Registra
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          
          <DialogContentText >
            <Typography variant="subtitle1">
              <Box sx={{ fontWeight: 'bold', m: 1 }}>
                Inserisci i dati per registrare la movimentazione di un articolo.
              </Box>
            </Typography>
          </DialogContentText>

          <Grid container columnSpacing={2} rowspacing={1} sx={{mt:1}}> 
            
          <Grid item xs={12} md={12} lg={12}>
              <TextField 
                id="ubicazioneField"
                margin="normal"
                fullWidth
                size="small"
                label="Ubicazione"
                autoComplete="off"
                value={barcodeForm.Ubicazione || ""}
                //inputRef={ubicazioneRef}
                
                //helperText={touched.FornitoreRagSoc && errors.FornitoreRagSoc}
                //error={Boolean(touched.FornitoreRagSoc && errors.FornitoreRagSoc)}
                onChange={(e) => changeHandler("Ubicazione", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField 
                id="articoloField"
                margin="normal"
                fullWidth
                size="small"
                label="Articolo"
                autoComplete="off"
                value={barcodeForm.Articolo || ""}
                //inputRef={articoloRef}
                //helperText={touched.FornitoreRagSoc && errors.FornitoreRagSoc}
                //error={Boolean(touched.FornitoreRagSoc && errors.FornitoreRagSoc)}
                onChange={(e) => changeHandler("Articolo", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField 
                id="quantitaField"
                margin="normal"
                fullWidth
                size="small"
                label="Quantità"
                autoComplete="off"
                value={barcodeForm.Quantita || ""}
                //inputRef={quantitaRef}
                //helperText={touched.FornitoreRagSoc && errors.FornitoreRagSoc}
                //error={Boolean(touched.FornitoreRagSoc && errors.FornitoreRagSoc)}
                onChange={(e) => changeHandler("Quantita", e.target.value)}
              />
            </Grid>


          </Grid>
          <Stack direction={"row-reverse"} spacing={2} mt={2}>
            <Button variant="contained" autoFocus color="success" onClick={() => OnRegistraClick()}>
              Registra
            </Button>
            <Button variant="contained" autoFocus color="warning" onClick={dialogClose} sx={{mr:2}}>
              Annulla
            </Button>
          </Stack>
        </DialogContent>

      </Dialog>
      </div>
    )
  }
  
  export default DialogUbicazioni
